import React from "react";
import { contactStageImages } from "../../../utils/imageResources";
import { useWindowSizeAndObjectPlacement } from "../../../utils/useWindowSize";
import SceneObject from "../../SceneObject";
import CardTextOverlay from "../../TextData/CardTextOverlay";
import areaTextData from "../../TextData/DataTextForAreas";
import LinkedInProfile from "./LinkedInProfile";



const ContactMeGroundLayer = React.memo(props => {
  const { yCoordinate } = useWindowSizeAndObjectPlacement();

  return (
    <SceneObject {...props} name="Contact Me Ground Layer">

      <SceneObject
        name="TEXT - Contact Me Card"
        transform={{
          position: { x: -280, y: yCoordinate - yCoordinate / 1.4 },
          scale: { x: 400, y: 400 },
          sdposition: { x: -300, y: 270 },  // Small screen position
          sdscale: { x: 300, y: 300 }, // Small screen position
          opacity: props.scroll > 22900 && props.scroll < 30300 ? 1 : 0.3,
          transition: "opacity 0.1s ease-in-out"
        }}>
        <CardTextOverlay
          data={areaTextData["ContactText"]}
        />
      </SceneObject>

      <SceneObject
        name="LinkedInProfile Container"
        transform={{
          position: { x: 300, y: yCoordinate - yCoordinate / 1.9 },
          scale: { x: 300, y: 300 },
          sdposition: { x: 300, y: yCoordinate - yCoordinate / 1.9 },  // Small screen position
          sdscale: { x: 300, y: 300 } // Small screen position
        }}
      >
        <LinkedInProfile
          name="Grigoli Vashakidze"
          position="Software Engineer"
          location="San Diego, CA"
          summary="Software Engineer | Tech Enthusiast | Empowering the Future with Code"
          profileImageURL={contactStageImages.LILogo}
          linkedInURL="https://www.linkedin.com/in/geegla/"
        />
      </SceneObject>
    </SceneObject>
  );
});

export default ContactMeGroundLayer;
