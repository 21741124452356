import { Component } from "react";
import FloatedLayer from "./FloatedLayer";

import SdsuSkyLayer from "../Stages/3_Education_SDSU/SdsuSkyLayer";
import SanDiegoSkyLayer from "../Stages/4_SanDiego/SanDiegoSkyLayer";
import GeorgiaSkyLayer from "../Stages/5_Georgia/GeorgiaSkyLayer";

import AnimateCalculation from "../../utils/AnimateCalculation";

// The Sky layer is the third layer. Behind the Ground and Environment

const stages = [
  // Work Experience (No specific sky layer, use neutral sky)
  {
    name: 'Work Start',
    startScroll: 0,
    endScroll: 5400,
    startPosition: { x: 0, y: 0 },
    endPosition: { x: -4000, y: 0 },
  },
  {
    name: 'Work Downhill',
    startScroll: 5400,
    endScroll: 6400,
    startPosition: { x: -4000, y: 0 },
    endPosition: { x: -4500, y: -5000 },
  },
  // Skills (Continue neutral sky)
  {
    name: 'Skills Area',
    startScroll: 6400,
    endScroll: 11500,
    startPosition: { x: -4500, y: -5000 },
    endPosition: { x: -7000, y: -700 },
  },
  // Education (SDSU Sky Layer)
  {
    name: 'SDSU Area',
    startScroll: 11500,
    endScroll: 16500,
    startPosition: { x: -7000, y: -700 },
    endPosition: { x: -8000, y: -700 },
  },
  {
    name: 'SDSU Downhill',
    startScroll: 16500,
    endScroll: 17500,
    startPosition: { x: -8000, y: -700 },
    endPosition: { x: -8500, y: 0 },
  },
  // Where I Currently Live and Work (San Diego Sky Layer)
  {
    name: 'San Diego Area',
    startScroll: 17500,
    endScroll: 19500,
    startPosition: { x: -8500, y: 0 },
    endPosition: { x: -11000, y: 0 },
  },
  {
    name: 'San Diego Uphill',
    startScroll: 19500,
    endScroll: 20500,
    startPosition: { x: -11000, y: 0 },
    endPosition: { x: -11800, y: -700 },
  },
  // Where I Come From (Georgia Sky Layer)
  {
    name: 'Georgia Area',
    startScroll: 20500,
    endScroll: 22000,
    startPosition: { x: -11800, y: -700 },
    endPosition: { x: -13700, y: -700 },
  },
  {
    name: 'Georgia Area - fast',
    startScroll: 22000,
    endScroll: 23200,
    startPosition: { x: -13700, y: -700 },
    endPosition: { x: -15500, y: -700 },
  },
  // Contact (End of sky)
  {
    name: 'Contact Me',
    startScroll: 23200,
    endScroll: 25000,
    startPosition: { x: -15500, y: -700 },
    endPosition: { x: -19000, y: -700 },
  },
];


export class Sky extends Component {
  constructor(props) {
    super(props);
    this.transform = { position: { x: 0, y: 0 } };

    // Convert stages to animations
    this.animations = stages.reduce((acc, stage) => {
      const key = `${stage.startScroll}-${stage.endScroll}`;
      acc[key] = {
        position: {
          x: [stage.startPosition.x, stage.endPosition.x],
          y: [stage.startPosition.y, stage.endPosition.y],
        },
      };
      return acc;
    }, {});
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.scroll !== nextProps.scroll) {
      this.transform = AnimateCalculation(this.animations, nextProps.scroll);
      return true;
    }
    return false;
  }

  render() {
    return (
      <FloatedLayer name="Sky Layer" transform={this.transform}>
        {/* Neutral Sky for Work Experience and Skills */}
        {/* <div
          style={{
            position: 'absolute',
            width: '10000px',
            height: '100%',
            backgroundColor: '#87CEEB', // Light sky blue
            transform: `translate(${-3000}px, ${0}px)`,
          }}
        /> */}
        {/* SDSU Sky Layer */}
        <SdsuSkyLayer
          {...this.props}
          transform={{ position: { x: 5500, y: 1000 } }}
        />
        {/* San Diego Sky Layer */}
        <SanDiegoSkyLayer
          {...this.props}
          transform={{ position: { x: 8000, y: 0 } }}
        />
        {/* Georgia Sky Layer */}
        <GeorgiaSkyLayer
          {...this.props}
          transform={{ position: { x: 12000, y: 700 } }}
        />
      </FloatedLayer>
    );
  }
}

export default Sky;
