import React from "react";
import { georgiaImages } from "../../../utils/imageResources";
import SceneObject from "../../SceneObject";
import Tree from "./Tree";

const GeorgiaGroundLayer = React.memo(props => {
  // This component is a SceneObject that contains the Georgia Ground Layer

  // The Georgia Ground Layer contains SceneObjects which are relatively possitioned to the ground layer
  // for example tree x position is 700, y position is 210 that means
  // the tree is 700px from the left and 210px from the top of the ground layer 


  return (
    <SceneObject {...props} name="Georgia Ground Layer">
      <SceneObject
        name="GRASS - FOREGROUND"
        color="#4bab3e"
        transform={{
          position: { x: -1500, y: -700 },
          scale: { x: 8500, y: 800 }
        }}
      />

      <SceneObject
        name="MAIN ROAD"
        color="grey"
        transform={{
          position: { x: -1500, y: 90 },
          scale: { x: 8500, y: 50 }
        }}
      />



      <SceneObject
        name="president-palace"
        transform={{
          position: { x: 100, y: 270 },
          scale: { x: 700, y: 1000 }
        }}
        $imgUrl={georgiaImages.PresidentPalaceImg}
      />

      <Tree transform={{ position: { x: -300, y: 210 }, scale: { x: 400, y: 703 } }} type="3" />
      {/* <Tree transform={{ position: { x: -550, y: 150 } }} type="2" /> */}
      <Tree transform={{ position: { x: -100, y: 200 }, scale: { x: 400, y: 703 } }} type="1" />
      {/* <Tree transform={{ position: { x: 900, y: 210 }, scale: { x: 400, y: 703 } }} type="6" />
      <Tree transform={{ position: { x: 1000, y: 200 } }} type="4" /> */}


      {/* <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 0, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={400}
          text="Welcome to my Interactive Resume!"
        />
      </SceneObject> */}

      {/* 
      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 1000, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={1000}
          text="Let's scroll through my journey, work experiences, and learn more about me."
        />
      </SceneObject> */}

      {/* <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 1500, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={1500}
          text="Oh, and most people call me Giga!"
        />
      </SceneObject>

 */}

      {/* 
      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 2100, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={2100}
          text="Now let's start with where I come from, which is Tbilisi, Georgia."
        />
      </SceneObject>

 */}


      {/* <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 3200, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={3200}
          text="My journey in technology started at a young age and has taken me across the globe."
        />
      </SceneObject> */}
      {/* 
      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 4200, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={4200}
          text="I moved to the vibrant city of San Diego, California, a hub for innovation and technology."
        />
      </SceneObject> */}

      <SceneObject
        name="president-palace-left-slope"
        color="grey"
        transform={{
          position: { x: 270, y: 81 },
          scale: { x: 50, y: 200 },
          rotation: 45
        }}
      />


      <SceneObject
        name="president-palace-left-slope-2"
        color="grey"
        transform={{
          position: { x: 270, y: 120 },
          scale: { x: 100, y: 100 },
          rotation: 45
        }}
      />

      <SceneObject
        name="president-palace-right-slope"
        color="grey"
        transform={{
          position: { x: 580, y: 81 },
          scale: { x: 50, y: 200 },
          rotation: -45
        }}
      />
      <SceneObject
        name="president-palace-right-slope-2"
        color="grey"
        transform={{
          position: { x: 530, y: 120 },
          scale: { x: 100, y: 100 },
          rotation: -45
        }}
      />
      <SceneObject
        name="president-palace-entrance"
        color="grey"
        transform={{
          position: { x: 350, y: 90 },
          scale: { x: 200, y: 180 }
        }}
      />
    </SceneObject>
  );
});

export default GeorgiaGroundLayer;
