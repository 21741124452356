import React from "react";
import { georgiaImages } from "../../../utils/imageResources";
import SceneObject from "../../SceneObject";
import Tree from "./Tree";


const GeorgiaSkyLayer = React.memo(props => {
  return (
    <SceneObject name="Georgia Sky Layer" {...props}>


      <SceneObject
        name="Main Sky Road Left Ramp"
        color="#5c5c5c"
        transform={{
          position: { x: 415, y: 166 },
          scale: { x: 240, y: 140 },
          rotation: 45
        }}
      />
      <SceneObject
        name="Main Sky Road"
        color="#5c5c5c"
        transform={{ position: { x: 500, y: 100 }, scale: { x: 2086, y: 270 } }}
      />
      <SceneObject
        name="Main Sky Road Right Ramp"
        color="#5c5c5c"
        transform={{
          position: { x: 2501, y: 166 },
          scale: { x: 240, y: 140 },
          rotation: 45
        }}
      />

      <SceneObject
        name="TbilisiSkyline"
        transform={{
          position: { x: 500, y: 369 },
          scale: { x: 1500, y: 1000 },
          rotation: props.transform.rotation,
          opacity: 1
        }}
        $imgUrl={georgiaImages.Skyline}
      />

      <SceneObject
        name="MtaTsminda"
        transform={{
          position: { x: 2050, y: 370 },
          scale: { x: 535, y: 1000 },
        }}
        $imgUrl={georgiaImages.MtatsmindaImg}
      />
      <SceneObject
        name="Sameba"
        transform={{
          position: { x: 1800, y: 250 },
          scale: { x: 400, y: 450 }
        }}
        $imgUrl={georgiaImages.SamebaImg}
      />

      <SceneObject
        name="FreedomBridge"
        transform={{
          position: { x: 1300, y: 368 },
          scale: { x: 450, y: 500 }
        }}
        $imgUrl={georgiaImages.FreedomBridgeImg}
      />
      {/* <SceneObject
        name="FreedomSquare"
        transform={{
          position: { x: 1250, y: 270 },
          scale: { x: 150, y: 750 }
        }}
        $imgUrl={georgiaImages.FreedomSquareImg}
      /> */}

      <Tree transform={{ position: { x: 300, y: 230 }, scale: { x: 300, y: 503 } }} type="3" />


    </SceneObject>
  );
});

export default GeorgiaSkyLayer;
