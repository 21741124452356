import React from "react";
import './Work.css';

import SceneObject from "../../SceneObject";

const WorkGroundLayer = React.memo((props) => {

  return (
    <SceneObject {...props} name="Work Ground Layer">

      <SceneObject
        name="MAIN ROAD"
        color="grey"
        transform={{ position: { x: -5000, y: 0 }, scale: { x: 11785, y: 170 } }}
      />
      <SceneObject
        name="GRASS - FOREGROUND"
        color="green"
        transform={{ position: { x: -5000, y: -500 }, scale: { x: 11800, y: 550 } }}
      />
      <SceneObject
        name="GRASS - FOREGROUND right-ramp"
        color="green"
        transform={{
          position: { x: 6650, y: -644 },
          scale: { x: 600, y: 700 },
          rotation: 301
        }}
      />
      <SceneObject
        name="MAIN ROAD right-ramp"
        color="grey"
        transform={{
          position: { x: 7030, y: -491 },
          scale: { x: 130, y: 800 },
          rotation: 301
        }}
      />


    </SceneObject>
  );
});

export default WorkGroundLayer;
