import React from "react";
import SceneObject from "../../SceneObject";
import BoxComponent from "../../TextData/BoxComponent";

import PalmTree from "./PalmTrees";

const SanDiegoGroundLayer = React.memo(props => {
  return (
    <SceneObject {...props} name="SanDiego Ground Layer">

      <SceneObject
        name="San Diego Right Ramp - uphill"
        color="grey"
        transform={{
          position: { x: 3844, y: -232 },
          scale: { x: 1100, y: 800 },
          rotation: -45
        }}
      />

      <SceneObject
        name="Lighter SAND upper ground"
        color="moccasin"
        transform={{
          position: { x: -100, y: -4830 },
          scale: { x: 6700, y: 5000 }
        }}
      />

      <SceneObject
        name="Darker Sand Lower ground"
        color="#f7caa3"
        transform={{
          position: { x: 0, y: 0 },
          scale: { x: 5800, y: 70 }
        }}
      />






      <PalmTree transform={{ position: { x: 650, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 800, y: 170 }, scale: { x: 500, y: 1000 } }} type="2" />
      <PalmTree transform={{ position: { x: 950, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />

      <PalmTree transform={{ position: { x: 2500, y: 170 }, scale: { x: 500, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 3000, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 3200, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />

      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 1000, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={18000}
          text="San Diego is not just where I live; it's where I thrive. "
        />
      </SceneObject>

      <SceneObject
        name="TEXT"
        transform={{
          position: { x: 2800, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={18900}
          text="The city’s blend of innovation and laid-back lifestyle is truly inspiring. "
        />
      </SceneObject>


    </SceneObject>
  );
});

export default SanDiegoGroundLayer;
