import React from "react";
import '../../../utils/DroppingText.css';
import BillboardWithText from "../../TextData/BillboardTextOverlay";
import resumeDataDict from "../../TextData/DataResume";

import { useWindowSizeAndObjectPlacement } from "../../../utils/useWindowSize";
import SceneObject from "../../SceneObject";
import House from "./House";
import WorkBuilding from "./WorkBuilding";
import WorkBuildingBMS from "./WorkBuildingBMS";



const WorkEnvironmentLayer = React.memo(props => {


    const { width, height } = useWindowSizeAndObjectPlacement();

    const resumeData = resumeDataDict;


    return (
        <SceneObject {...props} name="Work Environment Layer">


            <SceneObject
                name="TEXT - SCROLL DOWN"
                transform={{
                    position: { x: -2200, y: -50 },
                    scale: { x: 800, y: 323 },
                    sdposition: { x: -2080, y: -100 },  // Small screen position
                    sdscale: { x: 350, y: 350 } // Small screen position
                }}
            >
                <p className="instruction-text">Scroll to Discover <span className="arrow">⬍</span></p>
            </SceneObject>

            <SceneObject
                name="TEXT - Grigoli Vashakidze Interactive Resume"
                transform={{
                    position: { x: -2400, y: height * 0.75 },
                    scale: { x: 800, y: 323 },
                    sdposition: { x: -2175, y: height * 0.80 },  // Small screen position
                    sdscale: { x: 350, y: 350 } // Small screen position
                }}>
                <div className="dropping-text-container">
                    <h1 className="dropping-text dropping-text-1" style={{ animationDelay: '0s' }}>Grigoli Vashakidze</h1>
                    <h3 className="dropping-text dropping-text-3" style={{ animationDelay: '0.5s' }}>Interactive Resume</h3>
                </div>
            </SceneObject>


            <SceneObject
                name="Sand-background"
                color="moccasin"
                transform={{
                    position: { x: 0, y: 100 },
                    scale: { x: 6200, y: 200 }
                }}
            />


            {/* <SceneObject
                name="TEXT - Georgia card"
                transform={{
                    position: { x: -1400, y: 150 },
                    scale: { x: 400, y: 400 }
                }}>
                <BoxComponent
                    scroll={props.scroll}
                    start={200}
                    text="Let's scroll through my journey, work experiences, and learn more about me."
                />
            </SceneObject> */}

            <SceneObject
                name="TEXT - Bristol Myers Squibb"
                transform={{
                    position: { x: -1200, y: height * 0.75 },
                    scale: { x: 550, y: 323 },
                    sdposition: { x: -1400, y: height * 0.25 },  // Small screen position
                    sdscale: { x: width - width / 10, y: height } // Small screen position
                }}>
                <div className="dropping-text-container">
                    <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0s' }}>Work Experience at</h3>
                    <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0.5s' }}>Bristol Myers Squibb</h3>
                </div>
            </SceneObject>
            {/* 
            <SceneObject
                name="Header - Bristol Myers Squibb"
                transform={{
                    position: { x: -1400, y: height * 0.75 },
                    scale: { x: 800, y: 500 },
                    sdposition: { x: -1400, y: height * 0.80 },
                    sdscale: { x: 300, y: 300 }
                }}
                $imgUrl={headerImages.Header06}
            /> */}

            <SceneObject
                name="under-house-ground"
                color="grey"
                transform={{ position: { x: -700, y: 100 }, scale: { x: 700, y: 300 } }}
            />

            <WorkBuildingBMS transform={{ position: { x: -800, y: 150 }, scale: { x: 1500, y: 1100 } }} />

            <SceneObject
                name="Work Experience 4"
                transform={{
                    position: { x: 700, y: 300 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 700, y: 380 },
                    sdscale: { x: 300, y: 300 }
                }}>
                <BillboardWithText
                    data={resumeData["ManagerBMS"]}
                />
            </SceneObject>

            {/* Start new order with WorkBuilding and Work Experience 3, 2, 1 */}
            {/* 
            <SceneObject
                name="Header - Mitchell International"
                transform={{
                    position: { x: 1600, y: height * 0.75 },
                    scale: { x: 800, y: 500 },
                    sdposition: { x: 1600, y: height * 0.80 },
                    sdscale: { x: 300, y: 300 }
                }}
                $imgUrl={headerImages.Header04}
            /> */}

            <SceneObject
                name="TEXT - Mitchell International"
                transform={{
                    position: { x: 1700, y: height * 0.75 },
                    scale: { x: 550, y: 323 },
                    sdposition: { x: 1700, y: height * 0.25 },  // Small screen position
                    sdscale: { x: width - width / 10, y: height } // Small screen position
                }}>
                <div className="dropping-text-container">
                    <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0s' }}>Work Experience at</h3>
                    <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0.5s' }}>Mitchell International</h3>
                </div>
            </SceneObject>

            <WorkBuilding transform={{ position: { x: 2300, y: 150 }, scale: { x: 900, y: 1000 } }} />

            <SceneObject
                name="Work Experience 3"
                transform={{
                    position: { x: 3200, y: 300 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 3200, y: 380 },
                    sdscale: { x: 300, y: 300 }
                }}>
                <BillboardWithText
                    data={resumeData["SoftwareEngineerII"]}
                />
            </SceneObject>

            <SceneObject
                name="Work Experience 2"
                transform={{
                    position: { x: 4000, y: 300 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 4000, y: 380 },
                    sdscale: { x: 300, y: 300 }
                }}>
                <BillboardWithText
                    data={resumeData["SoftwareEngineerI"]}
                />
            </SceneObject>

            <SceneObject
                name="Work Experience 1 - Internship"
                transform={{
                    position: { x: 4800, y: 300 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 4800, y: 380 },
                    sdscale: { x: 300, y: 300 }
                }}>
                <BillboardWithText
                    data={resumeDataDict["SoftwareEngineerIntern"]}
                />
            </SceneObject>

            <SceneObject
                name="Sand-background-right-corner"
                color="grey"
                transform={{
                    position: { x: 5600, y: -310 },
                    scale: { x: 600, y: 600 },
                    rotation: 59
                }}
            />

            <House transform={{ position: { x: 5700, y: 110 }, scale: { x: 800, y: 1000 } }} />

        </SceneObject>


    );
});

export default WorkEnvironmentLayer;
