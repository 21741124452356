import { FC, useMemo } from 'react';

type StagesByScrollValue = {
    [key: number]: string;
};

type ScrollProgressBarProps = {
    scroll: number;
    maxScroll: number;
    stagesByScrollValue: StagesByScrollValue;
};

const ScrollProgressBar: FC<ScrollProgressBarProps> = ({
    scroll,
    maxScroll,
    stagesByScrollValue,
}) => {

    // Calculate progress bar width based on scroll value
    const progressWidth = useMemo(() => (scroll / maxScroll) * 100, [scroll, maxScroll]);

    return (
        <div style={{ padding: '10px 0', position: 'relative', width: '100%' }}>
            {/* Progress Bar with Dots */}
            <div style={styles.progressBarWrapper}>
                <div style={{ ...styles.progressBarFill, width: `${progressWidth}%` }} />

                {/* Dots with Conditional Labels */}
                <div style={styles.dotsWrapper}>
                    {Object.keys(stagesByScrollValue).map((stageValue, index, keys) => {
                        const stageScrollValue = parseInt(stageValue, 10);
                        const nextStageScrollValue = keys[index + 1] ? parseInt(keys[index + 1], 10) : maxScroll;

                        // Determine if the scroll value is within the current stage's range (until the next stage)
                        const isInRange = scroll >= stageScrollValue && scroll < nextStageScrollValue;

                        // Calculate the left position relative to maxScroll
                        const leftPosition = (stageScrollValue / maxScroll) * 100;

                        return (
                            <div
                                key={index}
                                style={{
                                    ...styles.dotContainer,
                                    left: `${leftPosition}%`,
                                }}
                            >
                                {/* Stage Dot */}
                                <div
                                    style={{
                                        ...styles.dot,
                                        backgroundColor: isInRange ? '#005cd8' : '#ccc',
                                    }}
                                />
                                {/* Display Stage Name if it's within range */}
                                {isInRange && (
                                    <p style={styles.stageName}>{stagesByScrollValue[stageScrollValue]}</p>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

// Define styles outside of the component to avoid re-creation on every render
const styles = {
    progressBarWrapper: {
        position: 'relative' as 'relative',
        height: 5,
        backgroundColor: '#e0e0e0',
    },
    progressBarFill: {
        height: '100%',
        backgroundColor: '#005cd8',
        transition: 'width 0.2s ease',
        position: 'absolute' as 'absolute',
        top: 0,
        left: 0,
    },
    dotsWrapper: {
        position: 'absolute' as 'absolute',
        top: '-15px', // Place dots above the progress bar
        left: 0,
        right: 0,
    },
    dotContainer: {
        position: 'absolute' as 'absolute',
        transform: 'translateX(-50%)',
        textAlign: 'center' as 'center',
        width: '20px',
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        margin: '0 auto',
        transition: 'background-color 0.2s ease',
    },
    stageName: {
        fontSize: '10px',
        color: '#005cd8',
        position: 'absolute' as 'absolute',
        top: '15px',
        whiteSpace: 'nowrap' as 'nowrap',
    },
};

export default ScrollProgressBar;
