import { textBackgroundImages } from "../../utils/imageResources";


interface ResumeData {
    width: number;
    imageUrl?: string;  // Optional, since you might compute it later
    textColor: string;
    headline: string;
    timePeriod: string;
    location: string;
    content: string[];
}

const imageUrls: { [key: number]: string } = {
    400: textBackgroundImages.Card400Img,
    600: textBackgroundImages.Billboard800Img,
}

const resumeDataDict: { [key: string]: ResumeData } = {
    'GeorgiaText': {
        width: 600,
        headline: "Software Engineer II",
        timePeriod: "Mar 2021 - Present",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "Artifact Deployment: Manage the deployment via Jenkins pipelines, achieving a 99% successful deployment rate.",
            "Python Flask Solutions: Develop a Python Flask API to automate key aspects of the release process, cutting readiness report times from hours to milliseconds.",
            "Proof of Concepts: Lead various POCs to evaluate new tools and methodologies, contributing to the adoption of improved deployment strategies and automation techniques."
        ]
    },
    'ManagerBMS': {
        width: 600,
        headline: "Manager, Scientific Data Engineering",
        timePeriod: "Dec 2023 - Present",
        imageUrl: textBackgroundImages.Billboard2_800Img,
        textColor: "#383434",
        // textColor: "white",
        location: "San Diego, CA",
        content: [
            "Technical Lead on projects managing big data from 5,000+ scientific instruments, improving research efficiency and ensuring data integrity.",
            "Driving innovative data management initiatives, including automated quality checks and metadata tagging to enhance searchability and standards compliance.",
            "Overseeing the development of lab instrument scheduling and monitoring systems to optimize lab workflows.",
        ]
    },
    'SoftwareEngineerII': {
        width: 600,
        headline: "Software Engineer II",
        timePeriod: "Mar 2021 - Dec 2023",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "Automated key processes to significantly reduce operational time and improve efficiency.",
            "Managed deployment pipelines, ensuring a high success rate and minimizing downtime.",
            "Led initiatives to evaluate and implement new tools and technologies, enhancing overall workflow and automation capabilities."
        ]
    },
    'SoftwareEngineerI': {
        width: 600,
        headline: "Software Engineer I",
        timePeriod: "Aug 2019 - Mar 2021",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "Streamlined release coordination by collaborating with build and QA teams, reducing downtime and rollback instances by 20%.",
            "Implemented real-time monitoring and analytics using advanced tools, enabling quicker issue diagnosis and resolution during deployments.",
            "Automated server provisioning with configuration management tools, ensuring consistent environments and minimizing manual setup errors."]
    },
    'SoftwareEngineerIntern': {
        width: 600,  // You can now provide a width
        headline: "Software Development Engineer Intern",
        timePeriod: "May 2018 - Aug 2018",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "Collaborated with cross-functional teams to develop new features and resolve issues, utilizing C# and Java microservices to enhance overall functionality.",
            "Contributed to the transition from AngularJS to React, with a focus on code refactoring and UI/UX improvements, resulting in a 20% improvement in page load time and positive user feedback.",
            "Shadowed senior engineers to gain hands-on experience with code reviews and best practices in software development and deployment processes."
        ],
    }
};



// // Fetch the imageUrl when you're using resumeDataDict
// Object.keys(resumeDataDict).forEach((key) => {
//     const data = resumeDataDict[key];
//     data.imageUrl = imageUrls[data.width];  // Assuming `width` will always match a key in `imageUrls`
// });

export default resumeDataDict;
