import React from "react";

import SceneObject from "../../SceneObject";

const SdsuGroundLayer = React.memo(props => {
  // X and Y positions here are relative to SdsuGroundLayer in Grounds
  return (
    <SceneObject {...props} name="Sdsu Ground Layer">
      <SceneObject
        name="SDSU Right Ramp - downhill"
        color="grey"
        transform={{
          position: { x: 4080, y: -897 },
          scale: { x: 1100, y: 800 },
          rotation: 45
        }}
      />

      <SceneObject
        name="SDSU Right Ramp - Sand"
        color="moccasin"
        transform={{
          position: { x: 4050, y: -990 },
          scale: { x: 1100, y: 800 },
          rotation: 45
        }}
      />


      <SceneObject
        name="SAND FOREGROUND"
        color="moccasin"
        transform={{
          position: { x: 500, y: -1900 },
          scale: { x: 4000, y: 1977 }
        }}
      />
      <SceneObject
        name="MAIN ROAD LEFT RAMP"
        color="grey"
        transform={{
          position: { x: 150, y: -485 },
          scale: { x: 130, y: 800 },
          rotation: 59
        }}
      />
      <SceneObject
        name="Sand-left-ramp"
        color="moccasin"
        transform={{
          position: { x: 48, y: -873 },
          scale: { x: 800, y: 800 },
          rotation: 59
        }}
      />
      <SceneObject
        name="Sand-left-ramp-2"
        color="moccasin"
        transform={{
          position: { x: 50, y: -1130 },
          scale: { x: 500, y: 500 },
        }}
      />
      <SceneObject
        name="MAIN ROAD"
        color="grey"
        transform={{
          position: { x: 524, y: 76 },
          scale: { x: 4000, y: 100 }
        }}
      />
      <SceneObject
        name="Blue piece at the start"
        color="#2e3fe6"
        transform={{
          position: { x: -300, y: -400 },
          scale: { x: 200, y: 200 }
        }}
      />
    </SceneObject>
  );
});

export default SdsuGroundLayer;
