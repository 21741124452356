import React, { Component } from 'react';
import FloatedLayer from './FloatedLayer';

import WorkGroundLayer from '../Stages/1_Work/WorkGroundLayer';
import CoreCompetenciesGroundLayer from '../Stages/2_CoreCompetencies/CoreCompetenciesGroundLayer';
import SdsuGroundLayer from '../Stages/3_Education_SDSU/SdsuGroundLayer';
import SanDiegoGroundLayer from '../Stages/4_SanDiego/SanDiegoGroundLayer';
import GeorgiaGroundLayer from '../Stages/5_Georgia/GeorgiaGroundLayer';
import ContactMeGroundLayer from '../Stages/6_ContactMe/ContactMeGroundLayer';

import AnimateCalculation from '../../utils/AnimateCalculation';

// The Ground layer is the first layer


// controlls movement of the scenes. basically here we programm
// how background moves left right based on stant and end of scroll
const stages = [
  // Work Experience
  {
    name: 'Work Area',
    startScroll: 0,
    endScroll: 5400,
    startPosition: { x: 0, y: 0 },
    endPosition: { x: -8800, y: 0 },
  },
  {
    name: 'Work Downhill',
    startScroll: 5400,
    endScroll: 6400,
    startPosition: { x: -8800, y: 0 },
    endPosition: { x: -9300, y: 300 },
  },
  // Skills
  {
    name: 'Projects Area',
    startScroll: 6400,
    endScroll: 11500,
    startPosition: { x: -9300, y: 300 },
    endPosition: { x: -14000, y: 300 },
  },
  // Education
  {
    name: 'SDSU Uphill',
    startScroll: 11500, // 1000
    endScroll: 12500,
    startPosition: { x: -14000, y: 300 }, // 500
    endPosition: { x: -14500, y: 0 },
  },
  {
    name: 'SDSU Area',
    startScroll: 12500,
    endScroll: 16500,
    startPosition: { x: -14500, y: 0 },
    endPosition: { x: -18500, y: 0 },
  },
  {
    name: 'SDSU Downhill',
    startScroll: 16500, // 1000
    endScroll: 17500,
    startPosition: { x: -18500, y: 0 },
    endPosition: { x: -19200, y: 700 }, // 700
  },
  // Where I Currently Live and Work
  {
    name: 'San Diego Area',
    startScroll: 17500,
    endScroll: 19500,
    startPosition: { x: -19200, y: 700 },
    endPosition: { x: -23000, y: 700 },
  },
  {
    name: 'San Diego Uphill',
    startScroll: 19500,
    endScroll: 20500,
    startPosition: { x: -23000, y: 700 },
    endPosition: { x: -23700, y: 0 },
  },
  // Where I Come From
  {
    name: 'Georgia Area',
    startScroll: 20500,
    endScroll: 25000,
    startPosition: { x: -23700, y: 0 },
    endPosition: { x: -31500, y: 0 },
  },
  // Contact
  {
    name: 'Contact Me',
    startScroll: 25000,
    endScroll: 23500,
    startPosition: { x: -31500, y: 0 },
    endPosition: { x: -32000, y: 0 },
  },
];

export class Ground extends Component {
  constructor(props) {
    super(props);
    this.transform = { position: { x: 0, y: 0 } };

    // Convert stages to animations
    this.animations = stages.reduce((acc, stage) => {
      const key = `${stage.startScroll}-${stage.endScroll}`;
      acc[key] = {
        position: {
          x: [stage.startPosition.x, stage.endPosition.x],
          y: [stage.startPosition.y, stage.endPosition.y],
        },
      };
      return acc;
    }, {});
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.scroll !== nextProps.scroll) {
      this.transform = AnimateCalculation(this.animations, nextProps.scroll);
      return true;
    }
    return false;
  }

  //   Increase position x numbers to seaprate scenes on Ground layer
  render() {
    return (
      <FloatedLayer name="GROUND" transform={this.transform} zindex={30}>
        {/* Work Experience */}
        <WorkGroundLayer
          {...this.props}
          transform={{ position: { x: 2000, y: 0 } }}
        />
        {/* Skills */}
        <CoreCompetenciesGroundLayer
          {...this.props}
          transform={{ position: { x: 9300, y: -300 } }}
        />
        {/* Education */}
        <SdsuGroundLayer
          {...this.props}
          transform={{ position: { x: 14000, y: 0 } }}
        />
        {/* Where I Currently Live and Work */}
        <SanDiegoGroundLayer
          {...this.props}
          transform={{ position: { x: 19200, y: -700 } }}
        />
        {/* Where I Come From */}
        <GeorgiaGroundLayer
          {...this.props}
          transform={{ position: { x: 25200, y: 0 } }}
        />
        {/* Contact */}
        <ContactMeGroundLayer
          {...this.props}
          transform={{ position: { x: 28500, y: 0 } }}
        />
      </FloatedLayer>
    );
  }
}

export default React.memo(Ground);
