import React from "react";
import { sanDiegoImages } from "../../../utils/imageResources";
import SceneObject from "../../SceneObject";
import LifeguardTower from "./LifeguardTower";
import PalmTree from "./PalmTrees";
import Sand from "./Sand";
import SurfBoards from "./SurfBoards";

const SanDiegoSkyLayer = React.memo(props => {
  return (
    <SceneObject {...props} name="SanDiego Sky Layer">
      <SceneObject
        name="Sun glow"
        transform={{
          position: { x: -50, y: -1200 },
          scale: { x: 4000, y: 3591 },
          opacity: 0.3
        }}
        $imgUrl={sanDiegoImages.CaBg}
      />
      <SceneObject
        name="Sun and Clouds"
        transform={{
          position: { x: 500, y: 200 },
          scale: { x: 2000, y: 1000 },
        }}
        $imgUrl={sanDiegoImages.StarAndClouds}
      />
      <SceneObject
        name="Ocean"
        color="blue"
        transform={{ position: { x: 0, y: 0 }, scale: { x: 5050, y: 303 } }}
      />
      <SceneObject
        name="SandOceanShore"
        color="moccasin"
        transform={{
          position: { x: -70, y: 45 },
          scale: { x: 150, y: 240 },
          rotation: 45
        }}
      />
      <SceneObject
        name="Sand-left-corner"
        color="moccasin"
        transform={{
          position: { x: -964, y: 63 },
          scale: { x: 1000, y: 240 }
        }}
      />
      <PalmTree transform={{ position: { x: -50, y: 210 }, scale: { x: 200, y: 1000 } }} type="3" />

      <PalmTree transform={{ position: { x: -250, y: 195 }, scale: { x: 200, y: 1000 } }} type="2" />

      <PalmTree transform={{ position: { x: -100, y: 195 }, scale: { x: 200, y: 1000 } }} type="3" />
      <SurfBoards transform={{ position: { x: 700, y: 210 }, scale: { x: 400, y: 253 } }} />
      <LifeguardTower transform={{ position: { x: 1500, y: 210 }, scale: { x: 400, y: 399 } }} />
      <PalmTree transform={{ position: { x: 450, y: 195 }, scale: { x: 300, y: 1000 } }} type="3" />
      <Sand transform={{ position: { x: -1000, y: 145 }, scale: { x: 7000, y: 120 } }} />

      <Sand transform={{ position: { x: -500, y: 160 }, scale: { x: 4000, y: 200 } }} />

      <PalmTree transform={{ position: { x: 650, y: 195 }, scale: { x: 250, y: 1000 } }} type="1" />
      <PalmTree transform={{ position: { x: 1400, y: 200 }, scale: { x: 250, y: 1000 } }} type="2" />

      <PalmTree transform={{ position: { x: 1900, y: 210 }, scale: { x: 250, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 2300, y: 195 }, scale: { x: 250, y: 1000 } }} type="1" />
      <PalmTree transform={{ position: { x: 2700, y: 150 }, scale: { x: 250, y: 1000 } }} type="3" />

      <PalmTree transform={{ position: { x: 3300, y: 210 }, scale: { x: 250, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 3400, y: 195 }, scale: { x: 250, y: 1000 } }} type="1" />
      <PalmTree transform={{ position: { x: 3500, y: 150 }, scale: { x: 250, y: 1000 } }} type="3" />

    </SceneObject>
  );
});

export default SanDiegoSkyLayer;
