let count = 0;
let lastDirection = 1;
let currentHero = "giga";

const playersShowUp = {
  "0-1125": { hero: "giga" },
  "1125-5120": { hero: "tesla" },
  "5120-23420": { hero: "giga" },
  "23420-24000": { hero: "space" }
};

/** search in `animations` by frame(scroll) */
const determineAnim = (scroll) => {
  // console.log('scroll:', scroll);
  try {
    let anim = null;
    const currFrame = scroll;

    let animRange;
    Object.keys(playersShowUp).some((k) => {
      animRange = k.split("-");
      //if it out of current range
      if (currFrame < animRange[0] || animRange[1] <= currFrame) {
        return false;
      }

      anim = playersShowUp[k];
      return true;
    });

    if (anim === undefined || anim === null || anim === "") return currentHero;

    return anim.hero;
  } catch (e) {
    return currentHero;
  }
};


const getWalkAnimation = (onScrolling, scrollDirection, scroll, players) => {
  const currentHero = determineAnim(scroll);
  const currentPlayer = players[currentHero];
  const { scale = { x: 250, y: 400 } } = currentPlayer;

  // Calculate the frame index based on the count
  const frameIndex = Math.floor(count / currentPlayer.speed) % currentPlayer.frames[scrollDirection].length;
  const frame = currentPlayer.frames[scrollDirection][frameIndex];


  // determine direction and reset count if direction changed
  if (onScrolling) {
    if (scrollDirection !== lastDirection) {
      count = 0;
    } else {
      count++;
    }
    lastDirection = scrollDirection;
  }

  return [frame, scale];
};


export default getWalkAnimation;
