import SceneObject from "../SceneObject";
import getJumpAnimation from "./PlayerJumpAnimations";
import getWalkAnimation from "./PlayerWalkEffect";
import PlayerWrapper from "./PlayerWrapper";

const Player = props => {

  const [frame, scale] = getWalkAnimation(
    props.onScrolling,
    props.scrollDirection,
    props.scroll,
    props.players
  );

  const getZIndexValue = (scrollPosition) => {
    // Define the scroll ranges and their corresponding zIndex values
    const zIndexRanges = {
      "10400-11300": 0,
    };

    // Iterate through the ranges to find where the current scroll position fits
    for (const range in zIndexRanges) {
      const [min, max] = range.split('-').map(Number);
      if (scrollPosition >= min && scrollPosition <= max) {
        return zIndexRanges[range];
      }
    }

    // Return a default zIndex if the scroll position doesn't fit any range
    return 2; // Adjust this default value as needed
  };

  const zIndexValue = getZIndexValue(props.scroll);

  // console.log("scroll", props.scroll);

  return (
    <PlayerWrapper name="Player Wrapper" $zIndex={zIndexValue}>
      {/* <>
        {props.scroll}
      </> */}
      <SceneObject
        name="Real Player"
        transform={{
          rotation: 0,
          scale: scale || { x: 250, y: 400 }
        }}
        $animation={getJumpAnimation(props.scroll)}
        $imgUrl={frame}
      />

      {/* <div>{props.scroll}</div> */}

    </PlayerWrapper>
  );
};

export default Player;