import React, { Component, MouseEvent, ReactNode } from "react";
import GenerateBackgroundColor from "../utils/GenerateBackgroundColor";
import PlayPauseIcons from "./PlayPauseIcons";
import ScrollProgressBar from "./ScrollProgressBar";

// Define the state type for the Higher-Order Component (HOC)
interface HOCState {
  scroll: number;
  onScrolling: boolean;
  scrollDirection: number;
  scrollDelta: number;
}


// The Higher-Order Component function
const WithScroll = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends Component<{}, HOCState> {
    // Initial state
    state: HOCState = {
      scroll: 0,
      onScrolling: false,
      scrollDirection: 1,
      scrollDelta: 0,
    };

    // Auto scroll variables
    oldScroll = 0;
    startScroll = 0;
    maxScroll = 23500;
    scrollIncrement = 2;
    autoScroll = false;
    autoStandbyTime = 3000;
    waitForScroll: NodeJS.Timeout | null = null;

    // When the component mounts
    componentDidMount() {
      this.setState({ scroll: this.startScroll });
      document.location.href = "#";
      this.hackTheScroll(this.state.scroll);
      window.addEventListener("scroll", this.animateWithScroll);
      this.autoAnimate();
    }

    // When the component unmounts
    componentWillUnmount() {
      window.removeEventListener("scroll", this.animateWithScroll);
    }

    // Function for auto-scrolling
    autoAnimate = () => {
      requestAnimationFrame(this.autoAnimate);
      if (this.autoScroll === true && this.state.scroll >= this.maxScroll) {
        this.autoScroll = false
        return;
      }
      if (!this.autoScroll) return;

      const newState: HOCState = { ...this.state, onScrolling: true, scrollDirection: 1 };
      if (this.state.scroll < this.maxScroll) newState.scroll += this.scrollIncrement;
      this.setState(newState);
      this.forceUpdate();
      this.hackTheScroll(this.state.scroll);
    };

    // Function to animate with manual scroll
    animateWithScroll = () => {
      if (this.autoScroll) return;
      const newScroll = window.scrollY < this.maxScroll ? window.scrollY : this.maxScroll;
      this.setState({
        onScrolling: true,
        scrollDirection: window.scrollY - this.oldScroll > 0 ? 1 : 0,
        scroll: newScroll,
      });
      this.oldScroll = window.scrollY;
      this.forceUpdate();
    };

    // Function to force a scroll
    hackTheScroll = (val: number) => {
      val = Math.floor(val);
      window.scrollTo(val, val);
    };

    // Decides if the component should update
    shouldComponentUpdate(nextProps: {}, nextState: HOCState) {
      return this.state.scroll !== nextState.scroll;
    }

    // Toggle the auto-scrolling mode
    toggleAutoScroll = (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      this.autoScroll = !this.autoScroll;
    };

    // Function to launch fullscreen mode
    launchIntoFullscreen = (element: HTMLElement) => {
      if ((element as any).requestFullscreen) {
        (element as any).requestFullscreen();
      } else if ((element as any).mozRequestFullScreen) { // Firefox
        (element as any).mozRequestFullScreen();
      } else if ((element as any).webkitRequestFullscreen) { // Chrome, Safari and Opera
        (element as any).webkitRequestFullscreen();
      } else if ((element as any).msRequestFullscreen) { // IE/Edge
        (element as any).msRequestFullscreen();
      }
    };


    // Rendering the component
    render(): ReactNode {
      return (
        <div
          style={{
            height: `${window.innerHeight + this.maxScroll}px`,
            background: GenerateBackgroundColor(this.state.scroll),
            transition: "background-color 2s",
            overflow: "hidden",
          }}
        >
          <WrappedComponent
            onScrolling={this.state.onScrolling}
            scroll={this.state.scroll}
            scrollDirection={this.state.scrollDirection}
            {...this.props}
          />
          <div style={{ position: "fixed", width: "100%" }}>
            <ScrollProgressBar
              scroll={this.state.scroll}
              maxScroll={this.maxScroll}
              stagesByScrollValue={{
                0: '',
                500: 'Work Experience',
                6000: 'Core Competencies',
                12000: 'Education',
                17000: 'San Diego',
                20000: 'Georgia',
                23000: 'Contact'
              }}
            />
            <div onClick={this.toggleAutoScroll}>
              {this.state.scroll > 100 && (
                <PlayPauseIcons size={48} color="#007bff" isPlaying={this.autoScroll} />
              )}


            </div>
            {/* <button id="goFullscreen" onClick={() => this.launchIntoFullscreen(document.documentElement)}>Go Fullscreen</button> */}
          </div>
        </div>
      );
    }
  }
  return HOC;
};

export default WithScroll;