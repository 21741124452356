import React, { Component } from "react";
import SdsuEnvironmentLayer from "../Stages/3_Education_SDSU/SdsuEnvironmentLayer";
import SanDiegoEnvironmentLayer from "../Stages/4_SanDiego/SanDiegoEnvironmentLayer";
import GeorgiaEnvironmentLayer from "../Stages/5_Georgia/GeorgiaEnvironmentLayer";
import FloatedLayer from "./FloatedLayer";

import AnimateCalculation from "../../utils/AnimateCalculation";
import WorkEnvironmentLayer from "../Stages/1_Work/WorkEnvironmentLayer";

// The Environment layer is the second layer. In between Ground and Sky

const stages = [
  // Work Experience
  {
    name: 'Work Area',
    startScroll: 0,
    endScroll: 5400,
    startPosition: { x: 0, y: 0 },
    endPosition: { x: -8800, y: 0 },
  },
  {
    name: 'Work Downhill',
    startScroll: 5400,
    endScroll: 6400,
    startPosition: { x: -8800, y: 0 },
    endPosition: { x: -9500, y: 300 },
  },
  // Skills
  {
    name: 'Projects Area',
    startScroll: 6400,
    endScroll: 11500,
    startPosition: { x: -9500, y: 300 },
    endPosition: { x: -15000, y: 300 },
  },
  // Education
  {
    name: 'SDSU Uphill',
    startScroll: 11500,
    endScroll: 12500,
    startPosition: { x: -15000, y: 300 },
    endPosition: { x: -15500, y: 0 },
  },
  {
    name: 'SDSU Area',
    startScroll: 12500,
    endScroll: 16500,
    startPosition: { x: -15500, y: 0 },
    endPosition: { x: -20000, y: 0 },
  },
  {
    name: 'SDSU DownHill',
    startScroll: 16500,
    endScroll: 17500,
    startPosition: { x: -20000, y: 0 },
    endPosition: { x: -21000, y: 700 },
  },
  // Where I Currently Live and Work
  {
    name: 'San Diego Area',
    startScroll: 17500,
    endScroll: 19500,
    startPosition: { x: -21000, y: 700 },
    endPosition: { x: -23700, y: 700 },
  },
  {
    name: 'San Diego Uphill',
    startScroll: 19500,
    endScroll: 20500,
    startPosition: { x: -23700, y: 700 },
    endPosition: { x: -24500, y: 0 },
  },
  // Where I Come From
  {
    name: 'Georgia Area',
    startScroll: 20500,
    endScroll: 25000,
    startPosition: { x: -24500, y: 0 },
    endPosition: { x: -32500, y: 0 },
  },
  // Contact
  {
    name: 'Contact Me',
    startScroll: 25000,
    endScroll: 23500,
    startPosition: { x: -32500, y: 0 },
    endPosition: { x: -34000, y: -1100 },
  },
];

export class Environment extends Component {
  constructor(props) {
    super(props);
    this.transform = { position: { x: 0, y: 0 } };

    // Convert stages to animations
    this.animations = stages.reduce((acc, stage) => {
      const key = `${stage.startScroll}-${stage.endScroll}`;
      acc[key] = {
        position: {
          x: [stage.startPosition.x, stage.endPosition.x],
          y: [stage.startPosition.y, stage.endPosition.y],
        },
      };
      return acc;
    }, {});
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.scroll !== nextProps.scroll) {
      this.transform = AnimateCalculation(this.animations, nextProps.scroll);
      return true;
    }
    return false;
  }

  render() {
    return (
      <FloatedLayer name="ENVIRONMENT" transform={this.transform}>
        {/* Work Experience */}
        <WorkEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 2000, y: 0 } }}
        />
        {/* Education */}
        <SdsuEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 16050, y: 0 } }}
        />
        {/* Where I Currently Live and Work */}
        <SanDiegoEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 21550, y: -700 } }}
        />
        {/* Where I Come From */}
        <GeorgiaEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 27000, y: 0 } }}
        />
      </FloatedLayer>
    );
  }
}

export default React.memo(Environment);
