import React from "react";

import { useWindowSizeAndObjectPlacement } from "../../../utils/useWindowSize";
import SceneObject from "../../SceneObject";
import BoxComponent from "../../TextData/BoxComponent";
import PalmTree from "../4_SanDiego/PalmTrees";
import AztecUnion from "./AztecUnion";
import Dimploma from "./Diploma";
import HepnerHall from "./HepnerHall";
import SdsuSign from "./SdsuSign";



const SdsuEnvironmentLayer = React.memo(props => {

  const { height } = useWindowSizeAndObjectPlacement();


  return (
    <SceneObject {...props} name="Sdsu Environment Layer">

      <SceneObject
        name="Sand-background-right-corner"
        color="moccasin"
        transform={{
          position: { x: 3330, y: -77 },
          scale: { x: 400, y: 277 },
          rotation: 45
        }}
      />

      <SceneObject
        name="Sand-background"
        color="moccasin"
        transform={{
          position: { x: 190, y: 100 },
          scale: { x: 3300, y: 200 }
        }}
      />
      <SceneObject
        name="Sand-background-left-corner"
        color="moccasin"
        transform={{
          position: { x: 35, y: 0 },
          scale: { x: 200, y: 277 },
          rotation: 55
        }}
      />

      <SceneObject
        name="Header"
        transform={{
          position: { x: -300, y: 0 },
          scale: { x: 400, y: 500 },
          sdposition: { x: -300, y: height * 0.25 },  // Small screen position
          sdscale: { x: 300, y: 300 } // Small screen position
        }}
      >
        <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0s' }}>Education</h3>
      </SceneObject>

      <SceneObject
        name="Hepner-Hall-Road"
        color="grey"
        transform={{
          position: { x: 700, y: 100 },
          scale: { x: 600, y: 200 }
        }}
      />
      <HepnerHall transform={{ position: { x: 750, y: 200 }, scale: { x: 500, y: 1000 } }} />

      <PalmTree transform={{ position: { x: 480, y: 100 }, scale: { x: 400, y: 1000 } }} type={"1"} />

      <Dimploma transform={{ position: { x: 1430, y: 330 }, scale: { x: 300, y: 1000 } }} />

      <PalmTree transform={{ position: { x: 1150, y: 100 }, scale: { x: 400, y: 1000 } }} type={"3"} />

      <SceneObject
        name="Aztec-Union-Road-Left_Corner"
        color="grey"
        transform={{
          position: { x: 1750, y: 0 },
          scale: { x: 300, y: 300 },
          rotation: 50
        }}
      />
      <SceneObject
        name="Aztec-Union-Road-Right_Corner"
        color="grey"
        transform={{
          position: { x: 2450, y: 0 },
          scale: { x: 300, y: 300 },
          rotation: -30
        }}
      />
      <SceneObject
        name="Aztec-Union-Road"
        color="grey"
        transform={{
          position: { x: 1900, y: 100 },
          scale: { x: 770, y: 200 }
        }}
      />
      <AztecUnion transform={{ position: { x: 1750, y: 250 }, scale: { x: 1000, y: 1000 } }} />
      <SdsuSign transform={{ position: { x: 150, y: 176 }, scale: { x: 500, y: 1000 } }} />

      <PalmTree transform={{ position: { x: 2550, y: 215 }, scale: { x: 350, y: 1000 } }} type={"2"} />

      <SceneObject
        name="SDSU Text"
        transform={{
          position: { x: 2900, y: 180 },
          scale: { x: 400, y: 400 },
          sdposition: { x: 2900, y: 270 },  // Small screen position
          sdscale: { x: 300, y: 300 }, // Small screen position
          // opacity: props.scroll > 15400 && props.scroll < 16000 ? 1 : 0.3
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={15400}
          text="Graduating from SDSU set a strong foundation for my career in tech."
        />
      </SceneObject>

    </SceneObject>
  );
});

export default SdsuEnvironmentLayer;
