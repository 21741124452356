import React from 'react';
import '../../../utils/DroppingText.css';
import { georgiaImages } from "../../../utils/imageResources";

import { useWindowSizeAndObjectPlacement } from '../../../utils/useWindowSize';
import SceneObject from "../../SceneObject";
import Tree from "./Tree";

const GeorgiaEnvironmentLayer = React.memo(props => {

  const { width, height } = useWindowSizeAndObjectPlacement();

  return (
    <SceneObject {...props} name="Georgia Environment Layer">
      <SceneObject
        name="GRASS - BACKGROUNDS right slope"
        color="#16993d"
        transform={{
          position: { x: -2156, y: 66 },
          scale: { x: 240, y: 140 },
          rotation: -45
        }}
      />

      <SceneObject
        name="FreedomSquare"
        transform={{
          position: { x: 50, y: 270 },
          scale: { x: 150, y: 750 }
        }}
        $imgUrl={georgiaImages.FreedomSquareImg}
      />

      <Tree transform={{ position: { x: -1700, y: 235 }, scale: { x: 300, y: 503 } }} type="1" />

      <Tree transform={{ position: { x: -200, y: 235 }, scale: { x: 300, y: 503 } }} type="1" />
      <Tree transform={{ position: { x: -300, y: 235 }, scale: { x: 270, y: 503 } }} type="3" />


      {/* <Tree transform={{ position: { x: -0, y: 235 }, scale: { x: 300, y: 503 } }} type="2" /> */}

      <Tree transform={{ position: { x: 550, y: 235 }, scale: { x: 270, y: 503 } }} type="3" />
      <Tree transform={{ position: { x: 500, y: 235 }, scale: { x: 250, y: 503 } }} type="1" />

      <SceneObject
        name="TEXT - Born in Georgia"
        transform={{
          position: { x: -2300, y: height * 0.75 },
          scale: { x: 550, y: 323 },
          sdposition: { x: -2350, y: height * 0.25 },  // Small screen position
          sdscale: { x: width - width / 10, y: height } // Small screen position
        }}>
        <div className="dropping-text-container">
          <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0s' }}>Born in Georgia</h3>
          <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0.5s' }}>The country not the state</h3>
        </div>
      </SceneObject>

      <SceneObject
        name="GRASS - BACKGROUNDS"
        color="#16993d"
        transform={{ position: { x: -2000, y: 0 }, scale: { x: 4400, y: 270 } }}
      />
      <SceneObject
        name="GRASS - BACKGROUNDS right slope"
        color="#16993d"
        transform={{
          position: { x: 2301, y: 66 },
          scale: { x: 240, y: 140 },
          rotation: 45
        }}
      />

      <Tree transform={{ position: { x: 1000, y: 235 }, scale: { x: 400, y: 503 } }} type="1" />
      <Tree transform={{ position: { x: 1100, y: 170 }, scale: { x: 450, y: 803 } }} type="6" />
      <Tree transform={{ position: { x: 1200, y: 190 } }} type="3" />
      <Tree transform={{ position: { x: 1400, y: 180 }, scale: { x: 600, y: 803 } }} type="6" />
      <Tree transform={{ position: { x: 1600, y: 170 }, scale: { x: 600, y: 803 } }} type="2" />
      {/* <Tree transform={{ position: { x: 1650, y: 150 } }} type="2" /> */}

    </SceneObject>
  );
});

export default GeorgiaEnvironmentLayer;
